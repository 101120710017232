



































































import { Component, Vue } from 'vue-property-decorator'
import { chargeSearch } from '_index/api/payment'
import { PaymentModule } from '_index/modules/payment'
interface UserItem {
  user_id: number
  user_avatar: string
  user_number: string
  user_nicename: string
}
@Component
export default class extends Vue {
  //keyword = '15283869305'
  keyword = ''
  userList: UserItem[] = []
  isShowDrowList = false
  async handleOk() {
    //
    if (!this.keyword.trim()) {
      this.$toast('账号不能为空，请重新输入')
      return
    }
    const params = {
      keyword: this.keyword,
      page: 1,
      page_size: 999
    }
    const { data } = await chargeSearch(params)
    if (data.list.length === 0) {
      this.$toast('账号不存在，请重新输入')
      return
    }
    this.userList = data.list
    this.isShowDrowList = true
    ;(this.$refs.dropRef as any).toggle()
  }
  handleCancelSearch() {
    this.isShowDrowList = false
    ;(this.$refs.dropRef as any).toggle()
    this.userList = []
  }
  curUser: Partial<UserItem> = {}
  showType = 'search'
  selectUser(item: any) {
    this.curUser = item
    this.showType = 'user'
    this.isShowDrowList = false
    PaymentModule.setUser(item)
    PaymentModule.initProductList(item.user_id)
    ;(this.$refs.dropRef as any).toggle()
  }
  changType() {
    this.keyword = ''
    this.curUser = {}
    this.showType = 'search'
  }
}
