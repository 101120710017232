























import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

import { WalletModule } from '@/store/modules/wallet'
@Component
export default class extends Vue {
  @Prop()
  item!: ChargeListItem
  @Prop()
  active!: boolean
  @Prop({
    type: String,
    default: ''
  })
  game_vip_name!: string
  handleClick() {
    WalletModule.setCustomInputStatus(true)
  }
  get showGive() {
    if (this.item.type && this.item.is_first_charge) {
      return this.item.give
    } else if (this.item.type == 0 && this.item.give > 0) {
      return this.item.give
    } else {
      return 0
    }
  }
  get showSendLabel() {
    if (this.item.type && this.item.is_first_charge) {
      return true
    } else if (this.item.type == 0 && this.item.label) {
      return true
    }
    return false
  }
}
