






























import { Component, Vue } from 'vue-property-decorator'
import { PaymentModule } from '_index/modules/payment'
import { WalletModule } from '@/store/modules/wallet'

@Component
export default class extends Vue {
  pay_arr = ['', '支付宝支付', '微信支付']
  get pay_index() {
    return PaymentModule.pay_index
  }
  get productList() {
    return PaymentModule.productList
  }
  get product_index() {
    return PaymentModule.product_index
  }
  get customInputStatus() {
    return WalletModule.customInputStatus
  }
  get customInputValue() {
    return WalletModule.customInputValue
  }
  get getBtnText() {
    const item = this.productList[this.product_index]
    const preText =
      this.pay_index === 1
        ? this.$t('使用微信支付充值')
        : this.$t('使用支付宝支付充值')

    if (item) {
      if (Number(this.customInputValue) > 0) {
        return preText + this.customInputValue + this.$t('元')
      }
      const val = item.money
      console.log('money', item.money)
      if (val == '0.00' || Number(val) <= 0) {
        return ''
      }
      return preText + (val || '') + this.$t('元')
    }
    return ''
  }
  changePayType(val: number) {
    //
    if (this.pay_index === val) return
    PaymentModule.setPayIndex(val)
  }
  recharge() {
    PaymentModule.pay(1)
  }
  toAgreement() {
    window.location.href = '/h5/main/user.html#/richH5?id=36'
  }
}
