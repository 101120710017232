

















































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Toast } from 'vant'
import SearchBox from './components/SearchBox.vue'
import PayList from './components/PayList.vue'
import WalletFooter from './components/WalletFooter.vue'
import { WalletModule } from '@/store/modules/wallet'
import { isIOS, getQueryVariable } from '@/common/utils'
import { handlePayAliPay, handlePayWeChatH5 } from '@/pages/index/api/payment'
import { getChargeList } from '_user/api'
import { PaymentModule } from '@/pages/index/modules/payment'
import PayMethod from '../user/wallet/components/PayMethod.vue'

import LimitPop from '../user/wallet/components/LimitPop.vue'
import Banner from '../user/wallet/components/Banner.vue'

import { LoyoutModule } from '@/store/modules/layout'
import CardBuy from '@/pages/videoGame/views/user/wallet/components/CardBuyNew.vue'
import DayGift from '@/pages/videoGame/views/user/wallet/components/DayGiftNew.vue'
@Component({
  components: {
    SearchBox,
    PayList,
    WalletFooter,
    PayMethod,
    LimitPop,
    Banner,
    CardBuy,
    DayGift
  }
})
export default class extends Vue {
  created() {
    PaymentModule.initProductList()
  }
  get title() {
    return LoyoutModule.title
  }
  get showAction() {
    return WalletModule.showMethod
  }
  get isUser() {
    return PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id
  }
  @Watch('isUser')
  async userChange(val: any) {
    await this.$nextTick()
    ;(this.$refs.cardBuyRef as any).getConfig()
    ;(this.$refs.dayGiftRef as any).getConfig()
  }
  activeTab = 0
  payInfo: Partial<ChargeListItem> = {}
  loading = true
  payList: ChargeListItem[] = []
  coin = 0
  payType = 1 //1.支付宝 2.微信
  isIOS = isIOS
  game_vip_name = ''
  requestFrom = getQueryVariable('requestFrom') || ''

  showCard = false
  async getChargeConfig() {
    const { data } = await getChargeList(undefined, 1)
    this.loading = false
    this.coin = data.coin
    this.payList = data.list
    this.game_vip_name = data.game_vip_name
    this.payType = data.charge
  }
  changePay(item: ChargeListItem) {
    const uid = PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id
    if (!uid) {
      Toast({
        message: '账号不能为空,请重新输入'
      })
      return
    }
    this.payInfo = item
    WalletModule.setShowMethod(true)
  }
  changePayType(val: number) {
    this.payType = val
  }
  recharge() {
    PaymentModule.pay(1)
  }
  closeAction() {
    WalletModule.setShowMethod(false)
  }
  goVip() {
    //this.$router.push('/payment/result')
    const uid = PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id
    if (!uid) {
      Toast({
        message: '账号不能为空,请重新输入'
      })
      return
    }

    window.location.href =
      '/h5/main/vip.html#/vip?outVip=1&uid=' +
      (PaymentModule.itunesInfo.id || PaymentModule.itunesInfo.user_id)
  }
}
