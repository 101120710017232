















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import PayListItem from './PayListItem.vue'
import { PaymentModule } from '_index/modules/payment'
import { WalletModule } from '@/store/modules/wallet'

@Component({
  components: {
    PayListItem
  }
})
export default class extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  game_vip_name!: string
  get productList() {
    return PaymentModule.productList
  }
  itemIndex = 0
  get product_index() {
    return PaymentModule.product_index
  }

  handleUserChooseProduct(index: number) {
    PaymentModule.setProductIndex(index)
    this.$emit('change', this.productList[this.product_index])
    WalletModule.setCustomInputValue('')
  }
}
